import * as firebase from "firebase/app"
import { storeWrapper } from "../redux-wrapper"
import { loginSuccessful, logoutSuccessful } from "../actions"

let firebaseUI, firebaseUIComponent
const UI_CONTAINER = '#firebaseui-auth-container'

export const showFirebaseAuthUI = () => {
  const ui = getFirebaseUIComponent()

  console.log('Command showFirebaseAuthUI ran')
  ui.start(UI_CONTAINER, getFirebaseUIConfig())
}

export const detectPendingRedirect = () => {
  const ui = getFirebaseUIComponent()

  if (ui.isPendingRedirect()) {
    console.log('Command detectPendingRedirect ran')
    ui.start(UI_CONTAINER, getFirebaseUIConfig())
  }
}

export const detectLoggedInUsers = () => {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      storeWrapper.store.dispatch(loginSuccessful(user))
    } else {
      storeWrapper.store.dispatch(logoutSuccessful())
    }
  });
}

export const logout = () => {
  return firebase.auth().signOut()
}

const getFirebaseUIComponent = () => {
  if (firebaseUIComponent) {
    return firebaseUIComponent
  } else {
    firebaseUI = require('firebaseui')
    return firebaseUIComponent = new firebaseUI.auth.AuthUI(firebase.auth())
  }
}

const getFirebaseUIConfig = () => ({
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      return false
    },
  },
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      requireDisplayName: true
    }
  ],
})