export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN'
export const BLOG_POST_PAGE_LOADED = 'BLOG_POST_PAGE_LOADED'
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL'
export const ATTEMPT_LOGOUT = 'ATTEMPT_LOGOUT'

export const attemptLogin = () => ({
  type: ATTEMPT_LOGIN
})

export const blogPostPageLoaded = () => ({
  type: BLOG_POST_PAGE_LOADED
})

export const loginSuccessful = (user) => ({
  user,
  type: LOGIN_SUCCESSFUL
})

export const logoutSuccessful = () => ({
  type: LOGOUT_SUCCESSFUL
})

export const attemptLogout = () => ({
  type: ATTEMPT_LOGOUT
})