import { loop, Cmd } from 'redux-loop';
import {
  ATTEMPT_LOGIN,
  BLOG_POST_PAGE_LOADED,
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
  ATTEMPT_LOGOUT
} from '../actions'
import {
  showFirebaseAuthUI,
  detectPendingRedirect,
  detectLoggedInUsers,
  logout,
} from '../commands'

const initialState = {
  isLoggedIn: false,
  user: undefined,
}

function authReducer(state = initialState, action) {
  switch(action.type) {
    case ATTEMPT_LOGIN:
      return loop(
        state,
        Cmd.run(showFirebaseAuthUI),
      )
    case ATTEMPT_LOGOUT:
        return loop(
          state,
          Cmd.run(logout),
        )
    case BLOG_POST_PAGE_LOADED:
      return loop(
        state,
        Cmd.list([
          Cmd.run(detectPendingRedirect),
          Cmd.run(detectLoggedInUsers),
        ])
      )
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        isLoggedIn: true,
        user: action.user,
      }
    case LOGOUT_SUCCESSFUL:
      return {
        ...state,
        isLoggedIn: false,
        user: undefined
      }
    default:
      return state
  }
}

export default authReducer