import React from 'react'
import { Provider } from 'react-redux'
import { createStore as reduxCreateStore } from 'redux'
import { install as installReduxLoop } from 'redux-loop'
import rootReducer from './reducers'

const initialState = undefined

const createStore = () => {
  const store = reduxCreateStore(rootReducer, initialState, installReduxLoop())
  storeWrapper.store = store
  return store
}

const ReduxWrapper = ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
)

export const storeWrapper = {
  store: undefined
}
export default ReduxWrapper