import "prismjs/themes/prism.css"
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDO5vl3zKUIXTHlQM6OXqaWN4khBcnspug",
  authDomain: "suvish-in-comments-d3b08.firebaseapp.com",
  databaseURL: "https://suvish-in-comments-d3b08.firebaseio.com",
  projectId: "suvish-in-comments-d3b08",
  storageBucket: "suvish-in-comments-d3b08.appspot.com",
  messagingSenderId: "138783541850",
  appId: "1:138783541850:web:e5f0351f0f0a8cabdd6663",
  measurementId: "G-JYD0ETPWXP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export { default as wrapRootElement } from './src/redux-wrapper'